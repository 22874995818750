import React from "react";
import "./ThirdSection.css";
import Post from "../topics/Post";

export default function ThirdSection() {
  return (
    <section id="key-advantages">
      <h1>
        Vantagens de ser <br />
        100% digital
      </h1>
      <h2>
        Você pode ter acesso aos nossos serviços de forma rápida,
        <br />
        fácil e segura, de qualquer lugar.
      </h2>
      <div className="benefits">
        <Post
          name="benefit"
          iconType="save-time"
          title="Economia de Tempo"
          content="Com a FAMIGLIA, você economiza tempo para fazer oque quiser,
          sem precisar agendar um atendimento ou esperar filas."
        />
        <Post
          name="benefit"
          iconType="comfort"
          title="Comodidade"
          content="Não importa onde voce esteja, a FAMIGLIA está sempre disponível para você, sem necessidade de sair de casa ou do trabalho."
        />
        <Post
          name="benefit"
          iconType="less-bureaucracy"
          title="Menos Burocracia"
          content="Não se preocupe mais com burocracias complicadas, Você pode se inscrever e gerenciar seus planos e seguros com facilidade, sem necessidade de uma tonelada de papéis"
        />
        <Post
          name="benefit"
          iconType="safe"
          title="Segurança"
          content="A nossa plataforma utiliza os mais altos padrões de segurança digital para projeteger as informações dos nossos clientes. Suas informações pessoais e financeiras estão seguras conosco."
        />
      </div>
    </section>
  );
}
