import React from "react";
import "./SecondSection.css";
import Post from "../topics/Post";

export default function SecondSection() {
  return (
    <section id="key-services">
      <div className="services">
        <Post
          name="service"
          iconType="logo"
          title="Conheça a FAMIGLIA SEGUROS"
          content="Nós estamos aqui para ajudá-lo a proteger o que é importante para
          você. Oferecemos uma ampla variedade de seguros e planos para
          atender às suas necessidades e das pessoas que você ama."
        />
        <Post
          name="service"
          iconType="remote-medical"
          title="ATENDIMENTO 100% ONLINE"
          content="Nossos especialistas são altamente capacitados e experientes,
            prontos para ajudá-lo a encontrar o plano ou seguro que melhor se
            adapta às suas necessidades e orçamento."
        />
        <Post
          name="service"
          iconType="award"
          title="OS MELHORES SERVIÇOS COM O MELHOR PREÇO"
          content="Nós trabalhamos com as principais seguradoras do mercado para
          oferecer as melhores opções de cobertura e preços."
        />
        <Post
          name="service"
          iconType="support"
          title="SUPORTE EXCLUSIVO"
          content="Oferecemos suporte durante todo o processo de sinistro, para
          garantir que você receba a ajuda necessária no momento em que mais
          precisa."
        />
      </div>
    </section>
  );
}
