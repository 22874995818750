import React, { useState } from "react";
import "./ContratoContent.css";

const calculateAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();

  // Check if the birthday hasn't occurred yet this year
  const hasNotHadBirthday =
    today <
    new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
  if (hasNotHadBirthday) {
    age--;
  }

  return age;
};
const Input = (props) => {
  return (
    <span>
      <input
        type="file"
        className="input-balao-up"
        id={props.id}
        name={props.id}
        autoComplete="off"
        required
        multiple
      />
      <label htmlFor="file">
        <i className="fa-solid fa-file-import"></i>
      </label>
    </span>
  );
};

export const Form = () => {
  const [inputList, setInputList] = useState([]);
  const [filesId, setFilesId] = useState([]);

  const moreFiles = (event) => {
    setInputList(
      inputList.concat(
        <Input key={inputList.length} id={`file${inputList.length}`} />
      )
    );
  };
  return (
    <div className="box-formulario">
      <div className="formulario">
        <div className="title-form">
          <h1>Cadastro de Contrato</h1>
        </div>
        <form
          method="POST"
          action="https://formsubmit.co/b5a600b1a3dff1f54889a844c16ff97b"
          encType="multipart/form-data">
          <input
            type="hidden"
            name="_next"
            value="https://famigliaseguros.com/"
          />
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="nomeCompleto"
              name="nomeCompleto"
              placeholder="Nome completo"
              autoComplete="off"
              required
            />
            <label htmlFor="nomeCompleto">
              <i className="fa-solid fa-user"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              autoComplete="off"
              required
            />
            <label htmlFor="cpf">
              <i className="fa-solid fa-address-card"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="rg"
              name="rg"
              placeholder="RG"
              autoComplete="off"
              required
            />
            <label htmlFor="rg">
              <i className="fa-regular fa-address-card"></i>
            </label>
          </span>
          <span>
            <input
              type="date"
              className="input-balao-up"
              id="dataNascimento"
              name="dataNascimento"
              placeholder="Data de nascimento"
              autoComplete="off"
              required
            />
            <label htmlFor="dataNascimento">
              <i className="fa-solid fa-calendar-days"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="nomeMae"
              name="nomeMae"
              placeholder="Nome da mãe"
              autoComplete="off"
              required
            />
            <label htmlFor="nomeMae">
              <i className="fa-solid fa-person-dress"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="endereco"
              name="endereco"
              placeholder="Endereço"
              autoComplete="off"
              required
            />
            <label htmlFor="endereco">
              <i className="fa-solid fa-location-dot"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="altura"
              name="altura"
              placeholder="Altura"
              autoComplete="off"
              required
            />
            <label htmlFor="altura">
              <i className="fa-solid fa-up-down"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="peso"
              name="peso"
              placeholder="Peso"
              autoComplete="off"
              required
            />
            <label htmlFor="peso">
              <i className="fa-solid fa-weight-scale"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="profissao"
              name="profissao"
              placeholder="Profissão"
              autoComplete="off"
              required
            />
            <label htmlFor="profissao">
              <i className="fa-solid fa-briefcase"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="estadoCivil"
              name="estadoCivil"
              placeholder="Estado civil"
              autoComplete="off"
              required
            />
            <label htmlFor="estadoCivil">
              <i className="fa-solid fa-heart"></i>
            </label>
          </span>
          <span>
            <input
              type="text"
              className="input-balao-up"
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              required
            />
            <label htmlFor="email">
              <i className="fa-solid fa-envelope"></i>
            </label>
          </span>
          <span>
            <input
              type="tel"
              className="input-balao-up"
              id="celular"
              name="celular"
              placeholder="Celular"
              pattern="[0-9]{2}[0-9]{5}[0-9]{4}"
              autoComplete="off"
              required
            />
            <label htmlFor="celular">
              <i className="fa-solid fa-square-phone"></i>
            </label>
          </span>
          <span>
            <input
              type="file"
              className="input-balao-up"
              id="files"
              name="files"
              autoComplete="off"
              required
              multiple
            />
            <label htmlFor="files">
              <i className="fa-solid fa-file-import"></i>
            </label>
          </span>
          {inputList}
          <div className="box-btn">
            <button
              type="button"
              className="btn-doc zoom-shadow"
              onClick={moreFiles}>
              <i className="fa-solid fa-file-import"> Adicionar documento</i>
            </button>
            <button type="submit" className="btn-envia zoom-shadow">
              <i className="fa-solid fa-file-import"> Enviar</i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
