import React from "react";
import HomeContent from "../content/HomeContent";

function Home() {
  return (
    <div>
      <HomeContent />
    </div>
  );
}

export default Home;
