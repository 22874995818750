import React from "react";
import "./Navbar.css";
import logo from "../img/others/logo-topo.png";

function Navbar() {
  return (
    <div>
      <div className="navbar">
        <h1> </h1>
        <a href="/">
          <img className="navbar-logo" src={logo} alt="Logo" />
        </a>
        <ul>
          <li>
            <a href="/">Área do Cliente</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
