import React from "react";
import { Form, Template, Template2 } from "../content/Contrato/ContratoContent";

function Contrato() {
  const isCheck = () => {
    return false;
  };
  return (
    <div>
      <Form />
      {/*isCheck() ? <Template /> : <Template2 />*/}
    </div>
  );
}

export default Contrato;
