import "./App.css";
import Navbar from "./components/global/Navbar";
import Contrato from "./components/pages/Contrato";
import Home from "./components/pages/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contrato" element={<Contrato />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
