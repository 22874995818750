import React from "react";
import { Button } from "../../buttons/Button";
import Post from "../topics/Post";

import "./FirstSection.css";

export default function FirstSection() {
  return (
    <section id="key-benefits">
      <h1>Segurança e tranquilidade para você e sua família!</h1>
      <h2>Conheça os serviços da FAMIGLIA SEGUROS E SAÚDE:</h2>
      <div className="benefits">
        <Post
          name="benefit"
          iconType="heart"
          title="PLANO DE SAÚDE"
          content="Temos Planos Familiar, Infantil, Gestante, Terceira Idade e Pet.
                É saúde para a FAMÍLIA TODA!"
        />
        <Post
          name="benefit"
          iconType="tooth"
          title="Plano odontológico"
          content="Um sorriso bonito é um sinal de saúde e bem-estar. Cuide do seu
            sorriso com nosso plano odontológico e sorria sem preocupações!"
        />
        <Post
          name="benefit"
          iconType="house"
          title="Seguro Residencial"
          content="Tenha sua casa protegida com seguro para incêndio, alagamentos,
            eventos naturais, roubo, furto, problemas hidráulicos, elétricos
            e muito mais. Tudo isso pelo MENOR PREÇO"
        />
      </div>
      <div className="benefit-resume">
        <p>
          Proteja você e sua família! Preencha nosso formulário de cotação ou
          entre em contato pelo WhatsApp para receber uma consultoria
          personalizada.
        </p>
        <Button type="submit" link="/" buttonStyle="btn--primary">
          Solicitar Cotação
        </Button>
      </div>
    </section>
  );
}
