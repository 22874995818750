import React from "react";
import "./Post.css";
import logo from "../../img/others/BRANCO - LOGO FAMIGLIA.png";

export default function Post(props) {
  const iconTypes = {
    heart: "fa-solid fa-heart-pulse",
    tooth: "fa-solid fa-tooth",
    house: "fa-solid fa-house-chimney",
    "remote-medical": "fa-solid fa-laptop-medical",
    award: "fa-solid fa-award",
    support: "fa-solid fa-comment-dots",
    logo: null,
    "save-time": "fa-solid fa-clock-rotate-left",
    comfort: "fa-solid fa-house-laptop",
    "less-bureaucracy": "fa-solid fa-file-circle-check",
    safe: "fa-solid fa-user-shield",
  };

  let icon = iconTypes[props.iconType] || "";

  return (
    <div className={props.name}>
      <div className={`${props.name}-img`}>
        {icon ? (
          <i className={icon}></i>
        ) : (
          <img className="logo" src={logo} alt="logo" />
        )}
      </div>
      <div id="text" className="text">
        <h4>{props.title}</h4>
        <p>{props.content}</p>
      </div>
    </div>
  );
}
