import React from "react";
import Header from "./sections/Header";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";
import FooterBar from "../global/FooterBar";

function HomeContent() {
  return (
    <div>
      <Header />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FooterBar />
    </div>
  );
}

export default HomeContent;
